import Axios from 'axios';
import moment from 'moment';

export default {
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      role: this.$store.state.role,
      user: {
        company: {},
      },
      company: {},
    }
  },
  mounted() {
    axios
    .get('/api/v1/companies/'+ this.$route.params.company +'/users/' + this.$route.params.id, {
      headers: {
        Authorization: 'Bearer ' + this.TOKEN,
      }
    })
    .then(response => {
      this.user = response.data.data;
    });
  },

  methods: {

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    removeUser: function (user) {
      let _this = this;
      axios
      .delete('/api/v1/companies/'+ this.$route.params.company +'/users/' + this.$route.params.id, {
        headers: {
          Authorization: 'Bearer ' + _this.TOKEN,
        }
      })
      .then(response => {
        $('#remove-account-modal').modal('hide');
        _this.$router.push({ path: '/dashboard/client/company' });
      });
    },

    loginAsUser() {
      axios
      .get('/api/v1/users/' + this.$route.params.id + '/login-as-user', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          var _this = this;
          // save token for future use
          _this.$store.commit('setToken', response.data.data.access_token);
          // save me for future use
          _this.me = response.data.data.user;
          _this.$store.commit('setMe', response.data.data.user);
          // redirect user to dashboard
          if (_this.me.admin) {
            _this.$router.push({ path: '/dashboard/admin' })
          } else if (_this.me.company_id) {
            _this.$router.push({ path: '/dashboard/client' })
          } else {
            _this.$router.push({ path: '/dashboard/professional' })
          }
        } else {
          console.log(response.data.error);
        }
      });
    },
  },

  computed: {
  },

}
